.sidebar_container {
  padding-top: 2rem;
  background-color: #2f4050;
  color: #808080;
  max-width: 215px;
  width: 100%;
  min-height: 100vh;
}

.sidebar li {
  width: 100%;
  padding: 1rem;
}

.sidebar li:hover {
  background-color: #293846;
  border-left: 5px solid rgb(3, 97, 3);
}
.sidebar li:hover a {
  color: #fff;
}

.sidebar li > * {
  text-decoration: none;
  color: #a7b1c2;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #efefef;
  list-style-type: none;
  padding: 1.5rem 0;
  font-size: 1.1rem;
}

.navbar li {
    margin: 0 1.5rem;
}

.navbar li > * {
  text-decoration: none;
  color: rgb(108,117,125);
}

.link-side {
  display: block;
}

.link-side:hover {
  color: #93a5c5;
}

.Layout {
  display: flex;
  position: relative;
  width: 100%;
}

.Authpage {
  /* background-color: var(--color-light-grey); */
}

.logout-btn {
  position: absolute;
  right: 1rem;
}

.logout-color {
  color: red !important;
}
.logout-color:hover {
  color: rgb(110, 6, 6) !important;
}

.outlet {
  max-width: calc(100% - 338px);
  width: 100%;
}

.main-content-section {
  padding: 0 0 0 30px;
}

.icon-margin {
  margin-right: 0.5rem;
}