* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-black: #000;
  --color-white: #fff;
  --color-grey: grey;
  --color-light-grey: #eee;
  --color-med-grey: #aaa;
  --color-dark-red: rgb(114, 28, 89);
  --color-light-red: rgb(248, 215, 218);
  --color-blue: rgb(13, 110, 253);
}
