@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-black: #000;
  --color-white: #fff;
  --color-grey: grey;
  --color-light-grey: #eee;
  --color-med-grey: #aaa;
  --color-dark-red: rgb(114, 28, 89);
  --color-light-red: rgb(248, 215, 218);
  --color-blue: rgb(13, 110, 253);
}

.sidebar_container {
  padding-top: 2rem;
  background-color: #2f4050;
  color: #808080;
  max-width: 215px;
  width: 100%;
  min-height: 100vh;
}

.sidebar li {
  width: 100%;
  padding: 1rem;
}

.sidebar li:hover {
  background-color: #293846;
  border-left: 5px solid rgb(3, 97, 3);
}
.sidebar li:hover a {
  color: #fff;
}

.sidebar li > * {
  text-decoration: none;
  color: #a7b1c2;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #efefef;
  list-style-type: none;
  padding: 1.5rem 0;
  font-size: 1.1rem;
}

.navbar li {
    margin: 0 1.5rem;
}

.navbar li > * {
  text-decoration: none;
  color: rgb(108,117,125);
}

.link-side {
  display: block;
}

.link-side:hover {
  color: #93a5c5;
}

.Layout {
  display: flex;
  position: relative;
  width: 100%;
}

.Authpage {
  /* background-color: var(--color-light-grey); */
}

.logout-btn {
  position: absolute;
  right: 1rem;
}

.logout-color {
  color: red !important;
}
.logout-color:hover {
  color: rgb(110, 6, 6) !important;
}

.outlet {
  max-width: calc(100% - 338px);
  width: 100%;
}

.main-content-section {
  padding: 0 0 0 30px;
}

.icon-margin {
  margin-right: 0.5rem;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  min-height: 100vh;
  width: 25%;
  margin: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.csv_file_name {
  padding-left: 1rem;
  font-weight: bold;
}

.parse_csv_btn {
  margin: 1rem 0;
}

.parse_csv_btn:first-child {
  margin-right: 1rem;
}

.username-cont {
  margin-top: 1rem;
}

.label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.35rem;
  width: 100% !important;
}

.login_btn_span {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-decoration: underline;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px auto 0;
  cursor: pointer;
}

.input {
  padding: 0.35rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: none;
  border: 1px solid #ccc;
  margin: 0;
}

.input:focus {
  outline: var(--color-light-grey);
  /* background-color: #ccc; */
}

.login-btn {
  margin-top: 0.75rem;
  padding: 0.35rem 0;
  border-radius: 0.25rem;
  border: none;
  background-color: var(--color-blue);
  color: var(--color-white);
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
}

.login-btn:hover {
  opacity: 0.8;
  cursor: pointer;
}

.error {
  color: var(--color-dark-red);
  background-color: var(--color-light-red);
  padding: 0.35rem 0.75rem;
  margin: 0.5rem 0 1rem;
  border-radius: 0.35rem;
}

.link {
  text-decoration: none;
  color: var(--color-white);
  background-color: var(--color-black);
  font-weight: 600;
  padding: 0.5rem 0.75rem;
}

.link:hover {
  opacity: 0.8;
}

.dashboardpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboardpage > * {
  margin-bottom: 0.75rem;
}

.top-left-link {
  position: absolute;
  top: -20%;
  left: 20%;
}

.create-pro-btn {
  position: relative;
}

.project-link {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.lead-list_item {
  text-decoration: none;
}

.oc_eyeicon_input {
  position: relative;
}

.zu_password_eye_btn {
  position: absolute;
  right: 20px;
  top: 9px;
  width: 18px;
  height: 18px;
  background: transparent;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #424242;
  z-index: 99;
  cursor: pointer;
}
.zu_password_eye_btn svg {
  width: 18px;
  height: 18px;
}

.reset_password_eye_btn {
  top: 12px;
}

.input_inside_label_text {
  position: absolute;
  color: #000000;
  left: 18px;
  font-size: 15px;
  font-weight: 600;
  top: 11px;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_inside_label_span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  right: 6px;
}

.icon_in_input_field {
  position: absolute;
  right: 20px;
  top: 9px;
  width: 18px;
  height: 18px;
  background: transparent;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #424242;
  z-index: 99;
}

.App {
  text-align: center;
}
/* comman css start */

iframe[style*="position: fixed;"] {
  display: none !important;
}

.m-0 {
  margin: 0 !important;
}

body,
html {
  overflow-x: hidden;
}

body {
  background: #f4f5f7;
  min-height: 100vh;
  height: auto;
  font-family: "Montserrat" !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #0f111133;
}

::-webkit-scrollbar-thumb:hover {
  background: #2a2e30;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

.ml-auto {
  margin-left: auto;
}

textarea,
button {
  font-family: "Montserrat" !important;
}

.Layout {
  display: flex;
}

#sidebar_toggel {
  display: none;
}

.main_content {
  max-width: calc(100% - 280px);
  width: 100%;
  transition: all 0.5s ease-in-out;
  margin-left: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 64px;
  z-index: 99;
}

#sidebar_toggel:checked ~ .main_content {
  max-width: calc(100% - 90px);
}

.loader {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-roller {
  /* display: inline-block; */
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.comman_btn {
  padding: 6px 20px;
  font-weight: 700;
  font-size: 14px;
  background: #2a2e30;
  border: 0;
  color: #ffffff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 10px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-width: 150px;
}

.create_licence_btn {
  margin: 0 !important;
}

.extra_btn_row {
  margin: 5px 0px 0px 10px !important;
  min-width: 207px;
}

.extra_btn_delete_icon_div {
  display: inline-block;
  margin: 5px 0px 0px 10px !important;
}

.extra_btn_delete_icon {
  padding: 6px 20px;
  font-weight: 700;
  font-size: 14px;
  background: #830000;
  border: 0;
  color: #ffffff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* margin: 10px; */
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 59px;
}

.extra_btn_delete_icon:hover {
  color: #ffffff;
  background: #c52f2f !important;
}

.extra_btn_deactive_icon_div {
  display: inline-block;
  margin: 5px 0px 0px 10px !important;
}

.extra_btn_deactive_icon {
  padding: 6px 20px;
  font-weight: 700;
  font-size: 14px;
  background: #830000;
  border: 0;
  color: #ffffff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* margin: 10px; */
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: 105px;
}

.extra_btn_deactive_icon:hover {
  color: #ffffff;
  background: #c52f2f !important;
}

.activate_btn {
  color: #ffffff;
  background: #088d4b !important;
}
.activate_btn:hover {
  color: #ffffff;
  background: #16ad55 !important;
}

.featured_btn {
  padding: 2px 10px;
}

.featured_btn:hover::after {
  content: " Add to Featured";
}
.unfeatured_btn {
  padding: 2px 10px;
}

.unfeatured_btn:hover::after {
  content: " Remove from Featured";
}

.featured_btn_width {
  width: 30%;
}

.save_featured_cont {
  display: block;
}

.save_featured {
  margin-top: 1rem;
}

.legal_doc_button {
  padding: 20px 0 0;
}

.basic-multi-select {
  z-index: 9999;
}

.basic-multi-select .select__control {
  display: flex !important;
  padding: 0 !important;
}

.basic-multi-select .select__control .select__indicators {
  margin: -6px 0 -6px 0;
}

.basic-multi-select .select__control .select__indicators .select__indicator {
  padding: 6px;
}

.basic-multi-select .select__control {
  display: flex;
  align-items: center;
  box-shadow: none;
  min-height: auto;
}

.error_filed .create_from_input,
.error_filed .basic-multi-select .select__control,
.error_filed
  .basic-multi-select
  .select__control
  .select__indicators
  .select__indicator,
.error_filed .create_from_input_file {
  color: #f00;
  border-color: #f00;
}

/* .comman_btn:last-child {
  margin-left: 2rem;
} */

.plan_list_id {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.create_from_bottom_btn {
  grid-gap: 16px;
  gap: 16px;
  display: flex;
  justify-content: flex-end;
}

.feedback_status-btn {
  justify-content: center;
}

.comman_btn:hover {
  background: #2a2e3033;
  color: #2a2e30;
}

.comman_btn:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.project_submit_bottom_btn {
  margin-top: 16px;
}

.project_submit_bottom_btn.center_back_btn {
  padding: 20px 0 0;
  max-width: 1100px;
  margin: 0 auto;
  grid-gap: 15px;
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.col_span_2 {
  grid-column: span 2 / span 2;
}

.error_modal_msg {
  color: #842029;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  display: block;
  position: fixed;
  right: 40px;
  top: 40px;
  z-index: 999;
}

.col_lg_3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.col_lg_4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.d_grid {
  display: grid;
}

.gap_15 {
  grid-gap: 18px;
  gap: 18px;
}

.footer_section {
  margin-top: auto;
  background: #ffffff;
  padding: 15px 40px;
  display: flex;
  align-items: center;
  box-shadow: 0px -15px 30px 0px rgb(0 0 0 / 2%);
  border-top: 2px solid #dddbdb;
}

body .project_common_select_input__control,
body .project_common_select_input__control:hover {
  border: 1px solid #c9a03540;
  border-radius: 6px;
}
body
  .project_common_select_input__control
  .project_common_select_input__value-container {
  padding: 4.5px 8px 4.5px 20px;
}
body
  .project_common_select_input__control.project_common_select_input__control--is-focused {
  border-color: #c9a03540;
  box-shadow: none;
}
body
  .project_common_select_input__control
  .project_common_select_input__value-container
  .project_common_select_input__single-value {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2a2e30;
}

.custom_select_no_option_text {
  color: red;
}

/* Date picker css start */
.custom_date_picker .react-datepicker {
  background: #ffffff;
  border-radius: 23.5px;
  border: 0;
  font-family: "Montserrat" !important;
  min-width: 327px;
}
.custom_date_picker .react-datepicker-popper {
  min-width: 327px;
}
.custom_date_picker .react-datepicker .react-datepicker__header {
  background: #f1e7cccc;
  border: 0;
  border-radius: 23.5px 23.5px 0 0;
  padding: 16px 24px 12px;
}
.custom_date_picker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.custom_date_picker
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  display: none;
}
.custom_date_picker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #f1e7cccc;
}
.custom_date_picker
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  border-top-color: #f1e7cccc;
}
.custom_date_picker .react-datepicker__current-month {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2a2e30;
  margin-bottom: 20px;
}
.custom_date_picker .react-datepicker__month-container {
  width: 100%;
}
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #2a2e30;
  width: 14.28%;
  margin: 0;
}
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__header
  .react-datepicker__day-names {
  margin-bottom: 0;
}
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__month {
  background: #f1e7cccc;
  margin: 0;
  border-radius: 0 0 23.5px 23.5px;
  padding: 0px 24px 22px;
}
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  width: calc(14.28% - 4px);
  height: 29px;
  margin: 2px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2a2e30;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--outside-month {
  color: rgba(42, 46, 48, 0.5);
}
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range,
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start,
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--keyboard-selected,
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--in-selecting-range {
  background: #c9a03587;
  box-shadow: none;
  color: #ffffff;
}
.custom_date_picker
  .react-datepicker__month-container
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day.react-datepicker__day--selected {
  background: #c9a035;
  box-shadow: 0px 6px 15px rgba(201, 160, 53, 0.6);
  color: #ffffff;
}

.custom_date_picker .react-datepicker__navigation {
  width: 20px;
  height: 20px;
  top: 16px;
}
.custom_date_picker .react-datepicker__navigation--previous {
  left: 25px;
}
.custom_date_picker .react-datepicker__navigation--next {
  right: 25px;
}
.custom_date_picker .react-datepicker__navigation-icon {
  top: 0;
  right: 0;
  font-size: 0px;
  line-height: 0;
  color: transparent;
  width: 20px;
  height: 20px;
}
.custom_date_picker .react-datepicker__navigation-icon {
  background-size: 10px 10px;
  background-position: center center;
  background-repeat: no-repeat;
}
.custom_date_picker
  .react-datepicker__navigation--previous
  .react-datepicker__navigation-icon {
  background-image: url(/static/media/datepickerLeftArrow.10ee7fd6.svg);
}
.custom_date_picker
  .react-datepicker__navigation--next
  .react-datepicker__navigation-icon {
  background-image: url(/static/media/datepickerRightArrow.0e2b01d7.svg);
}
.custom_date_picker .react-datepicker__navigation-icon::before {
  display: none;
}

/* Date picker css end */

/* Time picker css start */
.custom_date_time_picker .react-datepicker {
  border: 0;
}
.custom_date_time_picker .react-datepicker .react-datepicker__header {
  background: #f1e7cccc;
  border-bottom: 1px solid #c9a03540;
}
.custom_date_time_picker
  .react-datepicker
  .react-datepicker__header
  .react-datepicker-time__header {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2a2e30;
}
.custom_date_time_picker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2a2e30;
}
.custom_date_time_picker
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle,
.custom_date_time_picker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  margin-left: -15px;
}
.custom_date_time_picker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.custom_date_time_picker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #f4ecd6;
}
.custom_date_time_picker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  margin-top: -7px;
}

.custom_date_time_picker
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #c9a035;
  color: #ffffff;
}
/* Time picker css end */

/* comman css end */

/* sidebar css start */

.sidebar_container {
  box-shadow: 0px 15px 30px 0px rgb(0 0 0 / 2%);
  min-height: 100vh;
  height: auto;
  max-width: 280px;
  width: 100%;
  background: #fff;
  padding: 15px 20px;
  transition: all 0.5s ease-in-out;
  border-right: 1px solid #2a2e3028;
  position: fixed;
  z-index: 999;
}

.sidebar_container .sidebar_logo {
  background: transparent;
  width: 100%;
  border: 0;
  display: block;
}

.sidebar_container .sidebar_logo img {
  max-width: 200px;
  height: auto;
  width: 100%;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}

.sidebar_container .sidebar_nav {
  list-style: none;
  margin: 30px -20px -15px;
  max-height: calc(100vh - 81px);
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar_container .sidebar_nav::-webkit-scrollbar {
  width: 0;
}

/* Track */
.sidebar_container .sidebar_nav::-webkit-scrollbar-track {
  background: #0000001a;
}

/* Handle */
.sidebar_container .sidebar_nav::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 50px;
}

/* Handle on hover */
.sidebar_container .sidebar_nav::-webkit-scrollbar-thumb:hover {
  background: #000000;
  border-radius: 50px;
}

.sidebar_container .sidebar_nav {
  list-style: none;
  margin: 30px -20px -15px;
  max-height: calc(100vh - 81px);
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link {
  background: transparent;
  border: 0;
  width: 100%;
  padding: 12px 25px;
  font-size: 14px;
  font-weight: 500;
  color: #3f4358;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link::after {
  content: "";
  position: absolute;
  right: -1px;
  top: 50%;
  width: 0;
  height: 45px;
  border-radius: 60px 0 0 60px;
  background: #c9a035;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link svg {
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  color: #c9a035;
  margin-right: 16px;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(360deg, #dddbdb85 0%, #dddbdb85 113.33%),
    #dddbdb85;
  border-radius: 5px;
  padding: 7px;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link:hover,
.sidebar_container .sidebar_nav .sidebar_item .sidebar_link.active {
  color: #1e1e1e;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link:hover svg,
.sidebar_container .sidebar_nav .sidebar_item .sidebar_link.active svg {
  background: #c9a035;
  color: #ffffff;
}

.sidebar_container .sidebar_nav .sidebar_item .sidebar_link:hover::after,
.sidebar_container .sidebar_nav .sidebar_item .sidebar_link.active::after {
  width: 6px;
}

#sidebar_toggel:checked ~ .sidebar_container {
  max-width: 90px;
}

#sidebar_toggel:checked
  ~ .sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link
  span,
#sidebar_toggel:checked
  ~ .sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link::before {
  display: none;
}

#sidebar_toggel:checked
  ~ .sidebar_container
  .sidebar_nav
  .sidebar_item
  .sidebar_link
  svg {
  margin: 0 auto;
}

.sidebar_toggel_btn {
  cursor: pointer;
  color: #1e1e1e;
  min-width: 36px;
  max-width: 36px;
  height: 34px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.sidebar_toggel_btn:hover {
  background: #dddbdb;
}

.sidebar_toggel_btn svg {
  width: 20px;
}

.sidebar_container .sidebar_logo .small_sidebar_logo,
#sidebar_toggel:checked ~ .sidebar_container .sidebar_logo .full_sidebar_logo {
  display: none;
}

#sidebar_toggel:checked ~ .sidebar_container .sidebar_logo .small_sidebar_logo {
  display: block;
}

.sidebar_container .sidebar_logo .small_sidebar_logo {
  width: 49px;
  height: auto;
  margin: 0 auto;
}

/* #sidebar_toggel:checked  */

/* sidebar css end */

/* top header css end */

.top_header_section {
  background: #ffffff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  position: fixed;
  left: auto;
  top: 0;
  width: calc(100% - 280px);
  z-index: 99999;
  transition: all 0.5s ease-in-out;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

#sidebar_toggel:checked ~ .main_content .top_header_section {
  width: calc(100% - 89px);
  left: auto;
}

#sidebar_toggel:checked
  ~ .main_content
  .project_edit_content
  .project_edit_navbar {
  max-width: calc(100% - 89px);
  left: auto;
}

.top_header_section .top_navbar_nav {
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.top_header_section .top_navbar_nav .top_navbar_item .top_navbar_logout_btn {
  padding: 6px 20px;
  font-weight: 700;
  font-size: 14px;
  background: #2a2e30;
  border: 0;
  color: #ffffff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_header_section
  .top_navbar_nav
  .top_navbar_item
  .top_navbar_logout_btn
  svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.top_header_section
  .top_navbar_nav
  .top_navbar_item
  .top_navbar_logout_btn:hover {
  background: #2a2e3033;
  color: #2a2e30;
}

.top_header_section .page_name_heading {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
  line-height: 21.6px;
  text-transform: capitalize;
}

/* top header css end */

/* login css start */

.login_section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  padding: 0 15px;
  background-image: url(/static/media/loginBgImg.ac44c568.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.login_section::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00000094;
}

.login_section .login_content {
  max-width: 520px;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  z-index: 999;
}

.login_section .login_content .login_top_content {
  padding: 24px 24px 40px;
  background-image: url(/static/media/login-cover-image.56127e03.jpg);
  background-size: cover;
  border-radius: 4px 4px 0 0;
}

.login_section .login_content .login_top_content h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000000;
}

.login_section .login_content .login_top_content p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
  color: #000000;
}

.login_section .login_content .login_input_logo_content {
  padding: 0 20px 20px;
}

.login_section .login_content .login_input_logo_content .login_logo {
  width: 72px;
  height: 72px;
  background: #ffffff;
  border-radius: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -26px;
}

.login_section .login_content .login_input_logo_content .login_logo img {
  width: 65px;
  height: 65px;
  object-fit: contain;
}

.login_section .login_content .login_input_logo_content .login_input_content {
  padding: 32px 8px 8px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row,
.login_input_row {
  margin-bottom: 16px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .login_otp_input
  input {
  width: 36px !important;
  height: 36px !important;
}

.create_from_row .login_input_row input::-webkit-input-placeholder {
  color: #00000059 !important;
  letter-spacing: 2px;
}

.create_from_row .login_input_row input::placeholder {
  color: #00000059 !important;
  letter-spacing: 2px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .login_otp_input
  > div,
.login_input_row .login_otp_input > div {
  margin: 0 -10px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  .login_otp_input
  > div
  > div {
  margin: 0 10px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  color: #000000;
  width: 100%;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_input_row
  input,
.login_input_row input {
  display: block;
  width: 100%;
  padding: 7.5px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #000000;
  background-clip: padding-box;
  border: 1px solid #c9a035;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}

.create_from_row .login_input_row input {
  border: 1px solid #ced4da;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_btn {
  width: 100%;
  color: #ffffff;
  background-color: #000000;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #000000;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 10px;
}

.login_section
  .login_content
  .login_input_logo_content
  .login_input_content
  .login_btn:hover {
  background-color: #ffffff;
  color: #000000;
}

/* login css end */

/* footer css start */

.footer_section p {
  font-size: 14px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
}

.footer_section .footer_version_text {
  margin-left: auto;
}

/* footer css end */

/* common heading css start */

.common_heading {
  color: #2a2e30;
  font-size: 26px;
  border-radius: 4px;
  display: block;
  margin-bottom: 20px;
  font-weight: 600;
  border-left: 4px solid #c99f358e;
  padding-left: 10px;
}

.second_heading_in_page {
  margin: 0 auto;
  max-width: 1100px;
  margin-top: 16px;
  font-size: 20px;
  margin-bottom: 20px;
}

/* common heading css end */

/* project edit page css start */

.project_edit_main_content {
  padding: 20px;
}

.project_edit_main_link {
  margin-bottom: 20px;
}

.setting_page {
  margin-bottom: 5px;
}

.amenities_back_btn {
  margin-left: auto;
}

.project_edit_main_content .project_edit_detail_column_content {
  background: #ffffff;
  /* box-shadow: 0 0 8px 2px #0000000d; */
  /* padding: 15px; */
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
}

.project_edit_main_content .project_edit_detail_column_content h2,
.project_edit_main_content .custom_data_table_content h2 {
  font-size: 22px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #59667a;
}

/* .project_edit_main_content .custom_data_table_content h2 {
  color: #59667a !important;
} */

.project_edit_feature_input_dual_cointainer {
  display: flex;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file,
.project_edit_main_content .project_edit_feature_input_file {
  border: 1px solid #000000;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  input,
.project_edit_main_content .project_edit_feature_input_file input {
  display: none;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  label,
.project_edit_main_content .project_edit_feature_input_file label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dddbdbbd;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.create_from_label,
.login_input_row label {
  display: inline-block;
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  label
  span,
.project_edit_main_content .project_edit_feature_input_file label span {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 1px #00000035;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  label
  svg,
.project_edit_main_content .project_edit_feature_input_file label svg {
  width: 15px;
  height: 15px;
  color: #3f4358;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file:hover
  label,
.project_edit_main_content .project_edit_feature_input_file:hover label {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  img,
.project_edit_main_content .project_edit_feature_input_file img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project_container_for_modal {
  margin-top: 20px;
}

/* .project_edit_main_content .project_edit_detail_column_content h2 {
  font-size: 22px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #2c323f;
} */

.project_edit_main_content .project_edit_feature_input {
  margin-top: 16px;
}

.project_text_area {
  width: 100%;
  margin-top: 0 !important;
  box-shadow: none !important;
}

.project_text_area_label {
  width: 100%;
  margin-bottom: 8px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2a2e30;
}

.project_text_area_label_optional {
  color: #868686;
  font-size: 12px;
}

.project_common_select_input {
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border-color: #c9a035;
  color: #2a2e30;
  display: block;
  width: 100%;
  padding: 12px 20px;
  background-clip: padding-box;
  border: 1px solid #c9a03540;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 6px;
  outline: 0;
  box-shadow: none !important;
}

.project_common_select_input:focus {
  border: 1px solid #c9a03540;
  border-color: #c9a035;
  outline: none !important;
  box-shadow: none !important;
}

.project_edit_main_content .project_edit_feature_input input[type="file"] {
  display: none;
}

.project_edit_main_content .project_edit_feature_input label {
  width: 100%;
  margin-bottom: 8px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2a2e30;
}

.project_edit_main_content .project_edit_feature_input input,
.project_edit_main_content .project_edit_feature_input textarea {
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border-color: #c9a035;
  color: #2a2e30;
  display: block;
  width: 100%;
  padding: 12px 20px;
  background-clip: padding-box;
  border: 1px solid #c9a03540;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 6px;
  outline: 0;
}

.image_remove_btn {
  background: #3f4358;
  color: #fff;
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 7px;
  top: 7px;
  box-shadow: -2px 3px 8px 2px #00000035;
  cursor: pointer;
  z-index: 9900;
}

.image_remove_btn svg {
  width: 15px;
  height: 15px;
}

/* project edit page css end */

/* amenities page css start */

.creat_edit_project_btn_row {
  margin-bottom: 10px;
  /* text-align: right; */
}

/* Header and Filter Css start */

.creat_edit_project_btn_row_flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  flex-wrap: wrap;
}

.common_all_filter_row {
  display: flex;
  padding: 6px 5px;
  flex-wrap: wrap;
}

.common_select_box,
.common_filter_input_box {
  position: relative;
  width: 250px;
}

.project_date_range_input_container {
  position: relative;
}

.filter_input_inside_btn {
  position: absolute;
  top: 11px;
  right: 15px;
}

.filter_input_inside_btn svg {
  width: 20px;
}

.common_select_box label,
.common_filter_input_box label {
  display: none;
}

.common_select_box select,
.common_filter_input_box input {
  background: #ffffff;
  border-radius: 4px;
  padding: 12px 20px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  color: #2a2e30;
  background-image: url(/static/media/BottomArrow.165303e3.svg);
  background-size: 16px 9px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: right 20px center;
  outline: 0;
  box-shadow: none !important;
  border: 1px solid #e2e2e2 !important;
  width: 100%;
}

.common_filter_input_box input {
  background-image: none;
  border: 1px solid #c9a035 !important;
}

.common_select_box select {
  border: 1px solid #c9a035 !important;
}

.common_filter_input_box input::-webkit-input-placeholder {
  color: #2a2e30;
  font-size: 14px;
}

.common_filter_input_box input::placeholder {
  color: #2a2e30;
  font-size: 14px;
}

.common_select_box select {
  padding-right: 75px !important;
  font-weight: 500;
}
.common_select_box option {
  color: #000;
  font-weight: 500;
}

.common_select_box :disabled {
  color: rgb(161, 161, 161);
  font-weight: 500;
}

.first_filter_container {
  margin-right: 10px;
}

/* Header and Filter Css end */

.custom_data_table_content {
  overflow-x: auto;
}

.custom_data_table_content .custom_data_table {
  width: 100%;
  border-spacing: 0;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: #00000000 0px 0px 0px 0px, #00000000 0px 0px 0px 0px,
    #0000001a 0px 1px 3px 0px, #0000000f 0px 1px 2px 0px;
  min-width: 1130px;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_head
  .custom_data_table_heading {
  background: #c9a035;
  font-weight: 700;
  color: #ffffff;
  font-size: 14px;
  padding: 10px;
  text-transform: uppercase;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item {
  border-bottom: 1px solid #dddbdb;
  padding: 12px;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.table_item {
  text-align: center;
}
.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row:last-child
  .custom_data_table_item {
  border-bottom: 0;
}

.custom_data_table_view_edit_btn_item_row {
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 70px; */
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  margin: 0 auto;
}

.custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border: none;
  height: 30px;
  color: #2a2e30;
  background: #2a2e3033;
  border-radius: 4px;
  padding: 7px;
  transition: all 0.3s ease-in-out;
}

.custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn:hover {
  background: #2a2e30;
  color: #ffffff;
}

.delete_btn:hover {
  color: #ffffff;
  background: #c52f2f !important;
}

.custom_data_table_img {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.custom_data_table_img_sidemenu {
  background-color: #3f4358;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button,
.custom_data_table_button {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #3f4358;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
  background: #dddbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  margin: 0 auto;
  cursor: pointer;
}

.sold_button {
  margin-left: 0.5rem;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button:hover,
.custom_data_table_button:hover {
  color: #ffffff;
  background: #3f4358;
}

/* amenities page css end */

/* Announcement page css start */

.announcement_page-container {
  display: flex;
  align-items: center;
}

.comman_btn_container.center_back_btn {
  padding: 20px 20px 0;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.comman_btn_container {
  padding: 20px 20px 0;
}

.mobile_configuration_heading {
  color: #3f4358;
  display: inline-block;
  padding: 0.5rem;
  margin-top: 1rem;
  /* background: #3f4358; */
  border-radius: 0.2rem;
}

.create_from_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
  gap: 15px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -3px 6px 13px 0.5px #0000000d;
  padding: 20px 25px;
  max-width: 1100px;
  margin: 0 auto;
}

.full_detail_container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 15px;
  gap: 15px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -3px 6px 13px 0.5px #0000000d;
  padding: 20px 25px;
  max-width: 100%;
  margin-bottom: 25px;
  margin: 0 auto;
}

@media (max-width: 1300px) {
  .full_detail_container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 700px) {
  .full_detail_container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media (max-width: 500px) {
  .create_from_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.page_second_container {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: -3px 6px 13px 0.5px #0000000d;
  padding: 20px 25px;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 10px;
}

.create_from_second_row {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: 10px !important;
  margin-top: 20px;
}

.create_from_input_content .create_from_label,
.login_input_row label {
  display: inline-block;
  color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
}

.create_from_input_content .create_from_input {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.create_from_input_content .create_from_input::-webkit-input-placeholder, .project_edit_main_content .project_edit_feature_input input::-webkit-input-placeholder, .project_edit_main_content .project_edit_feature_input textarea::-webkit-input-placeholder {
  color: #2a2e3099 !important;
  /* letter-spacing: 2px; */
}

.create_from_input_content .create_from_input::placeholder,
.css-14el2xx-placeholder,
.project_edit_main_content .project_edit_feature_input input::placeholder,
.project_edit_main_content .project_edit_feature_input textarea::placeholder {
  color: #2a2e3099 !important;
  /* letter-spacing: 2px; */
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content {
  margin-bottom: 5px;
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content:last-child {
  margin-bottom: 0;
}

.create_from_row .create_from_input_content .create_from_radio_label,
.create_from_radio_label {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 21px;
  color: #717171;
  padding-left: 25px;
  cursor: pointer;
}

.create_from_row .create_from_input_content .create_from_radio_label::before,
.create_from_radio_label::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  border: 1px solid #717171;
  border-radius: 50%;
  background-color: #fff;
  transition: border 0.15s ease-in-out;
}

.create_from_row .create_from_input_content .create_from_radio_label::after,
.create_from_radio_label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 5px;
  top: 3px;
  border-radius: 50%;
  background-color: #717171;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
    -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.create_from_row
  .create_from_input_content
  .create_from_radio_label.checked::after,
.create_from_radio_label.checked::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.create_from_row .create_from_input_content .create_from_radio_checkbox {
  display: none;
}

.date-picker_input {
  padding: 2px 5px;
}

/* .user_data_search_select_box_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.user_data_search_select_box_row .user_data_search {
  position: relative;
  max-width: 230px;
  width: 100%;
  margin-right: 16px;
}

.user_data_search_select_box_row .user_data_search:last-child {
  margin-right: 0;
}

.user_data_search_select_box_row .user_data_search .user_data_search_input {
  padding-right: 35px;
} */

/* .user_data_search .user_data_search_input,
.basic-multi-select .select__control {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
} */

/* .user_data_search_select_box_row .user_data_search .user_data_search_btn {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  height: 100%;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
} */

/* .user_data_search_select_box_row .user_data_search .user_data_search_btn svg {
  height: 17px;
  width: 17px;
  color: #cccccc;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn:hover
  svg {
  color: #000;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 17px;
  background: #2524241a;
} */

/* .project_edit_feature_add_remove_btn_row {
  display: flex;
  align-items: center;
  margin: 16px 0;
  justify-content: space-between;
}

.project_edit_feature_add_remove_btn_row .project_edit_feature_add_remove_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #3f4358;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  cursor: pointer;
}

.project_edit_feature_add_remove_btn_row
  .project_edit_feature_add_remove_btn.project_edit_feature_add_btn {
  margin-left: auto;
}

.project_edit_feature_add_remove_btn_row
  .project_edit_feature_add_remove_btn
  svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.project_edit_feature_add_remove_btn_row
  .project_edit_feature_add_remove_btn:hover {
  color: #3f4358;
  background: #2524241a;
} */

/* Announcement page css start */

/* .user_data_search .user_data_search_input,
.basic-multi-select .select__control {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.basic-multi-select .select__control .select__value-container,
.basic-multi-select
  .select__control
  .select__value-container
  .select__single-value,
.basic-multi-select
  .select__control
  .select__value-container
  .select__input-container,
.basic-multi-select
  .select__control
  .select__value-container
  .select__input-container
  input {
  padding: 0;
  margin: 0;
  color: #717171;
}

.basic-multi-select .select__control .select__indicators {
  margin: -6px -6px -6px 0;
}

.basic-multi-select .select__control .select__indicators .select__indicator {
  padding: 6px;
}

.basic-multi-select .select__control {
  display: flex;
  align-items: center;
  box-shadow: none;
  min-height: auto;
}

.user_data_search_select_box_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.user_data_search_select_box_row .user_data_search {
  position: relative;
  max-width: 230px;
  width: 100%;
  margin-right: 16px;
}

.user_data_search_select_box_row .user_data_search .user_data_search_input {
  padding-right: 35px;
}

.user_data_search_select_box_row .user_data_search .user_data_search_btn {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: 0;
  height: 100%;
  width: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user_data_search_select_box_row .user_data_search .user_data_search_btn svg {
  height: 17px;
  width: 17px;
  color: #cccccc;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn:hover
  svg {
  color: #000;
}

.user_data_search_select_box_row
  .user_data_search
  .user_data_search_btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 17px;
  background: #cccccc;
}

.user_data_search_select_box_row .user_data_select {
  max-width: 220px;
  width: 100%;
}

.lead_status-options {
  margin-left: 1rem;
} */

/* user list page css end */

/* create page css start */

/* .create_from_row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: -3px 6px 13px 0.5px #0000000d;
  padding: 20px 25px;
  max-width: 1100px;
  margin: 0 auto;
}

.tower_dropdown {
  z-index: 99;
}

.plan_dropdown {
  z-index: 9;
}

.create_from_input_content .create_from_label {
  display: inline-block; */
/* color: #59667a;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  font-size: 14px;
}

.create_from_input_content .create_from_input {
  background-color: #fff;
  font-size: 14px;
  border-color: #e6edef;
  color: #717171;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: 0;
}

.create_from_input_content .create_from_input::placeholder,
.css-14el2xx-placeholder,
.project_edit_main_content .project_edit_feature_input input::placeholder,
.project_edit_main_content .project_edit_feature_input textarea::placeholder {
  color: #00000059 !important;
  letter-spacing: 2px;
}

.create_from_row .create_from_input_content .create_from_radio_checkbox {
  display: none;
}

.create_from_row .create_from_input_content .create_from_radio_label,
.create_from_radio_label {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 21px;
  color: #242934;
  padding-left: 25px;
  cursor: pointer;
}

.create_from_row .create_from_input_content .create_from_radio_label::before,
.create_from_radio_label::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  border: 1px solid #c11f3b;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}

.create_from_row .create_from_input_content .create_from_radio_label::after,
.create_from_radio_label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 5px;
  top: 3px;
  border-radius: 50%;
  background-color: #c11f3b;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33),
    -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.create_from_row
  .create_from_input_content
  .create_from_radio_label.checked::after,
.create_from_radio_label.checked::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content {
  margin-bottom: 5px;
}

.create_from_row
  .create_from_input_content
  .create_from_radio_checkbox_content:last-child {
  margin-bottom: 0;
}

.create_from_row .create_from_input_content input[type="file"] {
  display: none;
}

.create_from_row .create_from_input_content .create_from_file_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 1px solid #c11f3b;
  cursor: pointer;
  width: 100%;
  max-width: 150px;
}

.create_from_row .create_from_input_content .create_from_file_btn svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.create_from_row .create_from_input_content .create_from_input_file {
  border: 1px solid #000000;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.create_from_row .create_from_input_content .create_from_input_file label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c11f3b35;
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}

.create_from_row
  .create_from_input_content
  .create_from_input_file:hover
  label {
  transform: scale(1);
  opacity: 1;
}

.create_from_row .create_from_input_content .create_from_input_file label span {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 1px #00000035;
}

.create_from_row
  .create_from_input_content
  .create_from_input_file
  label
  span
  svg {
  width: 15px;
  height: 15px;
  color: #c11f3b;
}

.create_from_row .create_from_input_content .create_from_input_file img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.create_from_row .create_from_input_content .create_from_input_file img ~ label,
.project_edit_main_content
  .project_edit_detail_column_content
  .project_edit_feature_input_file
  img
  ~ label,
.create_from_input_file_specification img ~ label {
  display: none;
}

.create_from_row .create_from_input_content .create_from_plan_document_input {
  margin-top: 16px;
}

.create_from_row .create_from_input_content .create_from_extra_added_content {
  margin-bottom: 16px;
}

.create_from_row
  .create_from_input_content
  .create_from_extra_added_content:last-child {
  margin-bottom: 0;
}

.customer_properties_number_input .create_from_input {
  max-width: 50%;
}

.customer_properties_number_input .create_from_bottom_btn {
  margin-top: 16px;
}

.customer_properties_number_extra_added_content {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
}

.project_edit_detail_column_content .create_from_input_content {
  margin-bottom: 15px;
}

.project_edit_detail_column_content .create_from_input_content:last-child {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.project_detail-name {
  margin-left: 1rem;
  text-transform: uppercase;
  font-size: 14px;
  padding: 2px 7px;
  font-weight: 500;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #fff;
  vertical-align: middle;
  border-radius: 4px;
} */

/* create page css end */

/* error filed css start */

/* .error_filed .create_from_label, */
/* .error_filed .create_from_input::placeholder,
.error_filed .select__placeholder {
  color: #f00;
}

.error_filed .create_from_input,
.error_filed .basic-multi-select .select__control,
.error_filed
  .basic-multi-select
  .select__control
  .select__indicators
  .select__indicator,
.error_filed .create_from_input_file {
  color: #f00;
  border-color: #f00;
}

.error_filed .css-1okebmr-indicatorSeparator {
  background-color: #f00;
}*/

.error_filed .error_filed_text,
.err_text {
  color: #f00;
  font-size: 13px;
  display: inline-block;
  margin-top: 5px;
}

.error_text {
  color: #c11f3b;
  font-size: 14px;
  display: inline-block;
}

.link_sb {
  color: #c9a035;
}

.project_edit_link {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn,
.project_list_card_view_edit_btn {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #2a2e30;
  text-decoration: none;
  max-width: 165px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  background: transparent;
  border: 0;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn,
.project_list_card_view_edit_btn:hover {
  color: #2a2e30;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn
  svg,
.project_list_card_view_edit_btn svg {
  width: 40px;
  height: 28px;
  color: #2a2e30;
  background: #2a2e3033;
  border-radius: 4px;
  padding: 5px;
  margin-right: 8px;
  transition: all 0.5s ease-in-out;
}
.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:hover
  svg,
.project_list_card_view_edit_btn:hover svg {
  background: #2a2e30;
  color: #ffffff;
}

.project_list_card_view_edit_btn.link_yellow svg {
  background: #ffb8001a;
  color: #ffb800;
}

.project_list_card_view_edit_btn.link_yellow:hover svg {
  background: #ffb800;
  color: #ffffff;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:last-child {
  margin: 0 auto;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn
  svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:hover
  svg {
  background: #c11f3b;
  color: #ffffff;
}

/* .mobile-icon {
  padding: 6px 10px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  background-color: #c11f3b1a;
  border-radius: 4px;
} */

/* error filed css end */

/* project list card css start */

/* .project_list_card  {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px 18px 24px;
}

.project_list_card .project_list_card_img {
  width: 100%;
  box-shadow: 0px 20px 40px -20px rgba(0, 0, 0, 0.3),
    0px 30px 70px -40px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  min-height: 240px;
  max-height: 241px;
  object-fit: cover;
}

.project_list_card .project_list_card_body {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 275px);
}

.project_list_card .project_list_card_body .project_list_card_detaile {
  margin-bottom: 14px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.project_list_card .project_list_card_body h2 {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #c11f3b;
  margin-bottom: 9px;
}

.project_list_card .project_list_card_body p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #240006;
  font-weight: 400;
}

.project_list_card .project_list_card_body .project_list_card_bottom_btn_row {
  border-top: 2px solid #fbf1f3;
  margin-top: 14px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  margin-top: auto;
}

.project_edit_link {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn,
.project_list_card_view_edit_btn {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #c11f3b;
  text-decoration: none;
  max-width: 165px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn
  svg,
.project_list_card_view_edit_btn svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}
.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:hover
  svg,
.project_list_card_view_edit_btn:hover svg {
  background: #c11f3b;
  color: #ffffff;
}

.project_list_card_view_edit_btn.link_yellow svg {
  background: #ffb8001a;
  color: #ffb800;
}

.project_list_card_view_edit_btn.link_yellow:hover svg {
  background: #ffb800;
  color: #ffffff;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:last-child {
  margin: 0 auto;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn
  svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

.project_list_card
  .project_list_card_body
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn:hover
  svg {
  background: #c11f3b;
  color: #ffffff;
} */

/* project list card css end */

/* lead page css start */

/* .referrals_label_row {
  display: flex;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  gap: 15px;
}

.referrals_label_row .referrals_label_item,
.referrals_label_row .color_label_item {
  padding-left: 18px;
  position: relative;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.007em;
  color: #240006bf;
}

.referrals_label_row .referrals_label_item::before,
.referrals_label_row .color_label_item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #c11f3b;
  border-radius: 50px;
}

.referrals_label_row .referrals_label_item.submitted::before,
.lead_list_column .lead_list_label_time_text_row.submitted::before {
  background: #79dee8 !important;
}

.referrals_label_row .referrals_label_item.confirmed::before,
.lead_list_column .lead_list_label_time_text_row.confirmed::before {
  background: #824de1 !important;
}

.referrals_label_row .referrals_label_item.visited::before,
.lead_list_column .lead_list_label_time_text_row.visited::before {
  background: #e3c15f !important;
}

.referrals_label_row .referrals_label_item.converted::before,
.lead_list_column .lead_list_label_time_text_row.converted::before {
  background: #c1e865 !important;
}

.referrals_label_row .pending::before,
.lead_list_column .lead_list_label_time_text_row.pending::before {
  background: #e10000 !important;
}

.referrals_label_row .resolved::before,
.lead_list_column .lead_list_label_time_text_row.resolved::before {
  background: #ffb800 !important;
}

.referrals_label_row .closed::before,
.lead_list_column .lead_list_label_time_text_row.closed::before {
  background: #1dce00 !important;
}

.lead_list_row {
  margin: 16px;
}

.lead_list_column {
  padding: 18px;
  background: #ffffff;
  border-radius: 5px;
}

.lead_list_column .lead_list_label_time_text_row {
  display: flex;
  align-items: center;
  padding: 5px 0;
  position: relative;
  margin-bottom: 12px;
  padding-left: 13px;
}

.lead_list_column .lead_list_label_time_text_row .lead_list_label_text {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #240006bf;
  text-transform: capitalize;
}

.lead_list_column .lead_list_label_time_text_row::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 100%;
  background: #c11f3b;
  border-radius: 10px;
}

.lead_list_column .lead_list_label_time_text_row .lead_list_time_text {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.007em;
  color: #240006bf;
  margin-left: auto;
}

.lead_list_column .lead_list_name_other_detail .lead_list_proparty_name {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #240006bf;
  font-weight: 400;
  margin-bottom: 12px;
}

.lead_list_column .lead_list_name_other_detail .lead_list_user_name {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.007em;
  text-transform: uppercase;
  color: #240006;
  margin-bottom: 8px;
}

.lead_list_column .lead_list_name_other_detail .lead_list_user_contact_text {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.007em;
  color: #240006bf;
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn_contact_text {
  display: flex;
  align-items: center;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_btn {
  margin-left: auto;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  transition: all 0.5s ease-in-out;
  background: #c11f3b1a;
  border-radius: 5px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_btn:hover {
  background: #c11f3b;
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn.active
  svg {
  transform: rotate(0);
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_btn svg {
  transform: rotate(180deg);
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn
  svg
  path {
  transition: all 0.5s ease-in-out;
}

.lead_list_column
  .lead_list_name_other_detail
  .lead_list_collapse_btn:hover
  svg
  path {
  stroke: #ffffff;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_body {
  padding-top: 12px;
  margin-top: 8px;
  border-top: 1px solid #24000614;
  display: none;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_body p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.007em;
  color: #24000680;
}

.lead_list_column .lead_list_name_other_detail .lead_list_collapse_body.show {
  display: block;
}

.lead_project {
  z-index: 99;
}

.lead_referred {
  z-index: 9;
}

.lead_table-status {
  padding: 7px 12px;
  background-color: rgba(49, 49, 49, 0.1);
}

.lead_confirmed {
  color: rgb(155, 155, 5) !important;
}
.lead_submitted {
  color: rgb(5, 5, 146) !important;
}
.lead_rejected {
  color: #c11f3b !important;
}
.lead_visited {
  color: rgb(2, 78, 2) !important;
}
.lead_converted {
  color: rgb(3, 128, 128) !important;
} */

/* lead page css end */

/* media query css end */

/* .companydetail_image {
  display: flex;
  width: 50%;
}

.edit_view_icon {
  display: flex;
  align-items: space-around;
} */

/* .back_btn {
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding: 3px 10px;
} */

/*

.remove_pdf_btn {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  cursor: pointer;
  margin-top: 10px;
  margin-left: auto;
}

.remove_pdf_btn:hover {
  color: #ffffff;
  background: #c11f3b;
}

.remove_pdf_btn svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.create_from_input_file_specification {
  border: 1px solid #000000;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  color: #f00;
}

.create_from_input_file_specification label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c11f3b35;
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.create_from_input_file_specification:hover label {
  transform: scale(1);
  opacity: 1;
}

.create_from_input_file_specification label span {
  width: 35px;
  height: 35px;
  background: #ffffff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 1px #00000035;
}

.create_from_input_file_specification label span svg {
  width: 15px;
  height: 15px;
  color: #c11f3b;
}

.create_from_input_file_specification img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.specification_image {
  display: flex;
}

.lead_date {
  display: flex;
}

.lead_date > * {
  padding: 0.5rem 0.1rem;
  color: grey;
  margin: 0 0 0.5rem 0;
  border-radius: 0.2rem;
}

.lead-col_item {
  width: 30%;
  max-width: 50rem;
}

.plan_list-image {
  width: 100%;
}

.text-center {
  text-align: center;
}

.tech_font {
  font-size: 1.25rem !important;
}

.tech_docs {
  display: flex;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.table {
  margin-top: 3rem;
}

.table-head {
  background-color: #ccc;
}

.table-heading {
  padding: 0.35rem 0;
}

.table-body {
  padding: 0.5rem 0;
}

.table-body-row {
  background-color: #eee;
}

.table-body-row:hover {
  background-color: #ddd;
}

.table-body-link {
  text-decoration: none;
} */

/* about page css start */

/* .about_page_section {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  max-width: 1100px;
  margin: 0 auto;
}

.about_page_edit_create_btn {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
}

.about_page_section .about_page_slider {
  padding-bottom: 50px;
  border-bottom: 2px solid #fbf1f3;
  margin: 38px auto 20px;
} */

/* .about_page_section .about_page_slider .slick-slider {
  box-shadow: 0px 20px 40px -20px rgb(0 0 0 / 30%),
    0px 30px 70px -40px rgb(0 0 0 / 60%);
} */

/* .about_page_section .about_page_slider .slick-slider,
.about_page_section .about_page_slider .slick-slider .slick-slide,
.about_page_section .about_page_slider .slick-slider .slick-slide img {
  max-height: 350px;
  min-height: 350px;
  max-width: 765px;
  margin: 0 auto;
}

.about_page_section .about_page_slider .slick-slider .slick-slide img {
  width: 100%;
  object-fit: cover;
}

.about_page_section .about_page_slider .slick-slider .slick-prev,
.about_page_section .about_page_slider .slick-slider .slick-next {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background: #c11f3b1a;
  z-index: 999;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  top: -25px;
}

.about_page_section .about_page_slider .slick-slider .slick-prev:hover,
.about_page_section .about_page_slider .slick-slider .slick-next:hover {
  opacity: 1;
  background: #c11f3b;
}

.about_page_section .about_page_slider .slick-slider .slick-prev::before {
  content: "";
  border: solid #c11f3b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: translate(-50%, -50%) rotate(135deg);
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  left: 58%;
  top: 50%;
  position: absolute;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.about_page_section .about_page_slider .slick-slider .slick-next::before {
  content: "";
  border: solid #c11f3b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  left: 44%;
  top: 50%;
  position: absolute;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.about_page_section .about_page_slider .slick-slider .slick-prev:hover::before,
.about_page_section .about_page_slider .slick-slider .slick-next:hover::before {
  border-color: #ffffff;
}

.about_page_section .about_page_slider .slick-slider .slick-prev {
  left: auto;
  right: 30px;
}

.about_page_section .about_page_slider .slick-slider .slick-list {
  border-radius: 10px;
}

.about_page_section .about_page_slider .slick-slider .slick-next {
  right: 0;
}

.about_page_section .about_page_slider .slick-slider .slick-slide {
  cursor: grab;
}

.about_page_section .about_page_slider .slick-slider .slick-slide:active {
  cursor: grabbing;
}

.about_page_section .about_detaile_text_content {
  margin-bottom: 20px;
}

.about_page_section .about_detaile_text_content:last-child {
  margin-bottom: 0;
}

.about_page_section .about_detaile_text_content h2,
.about_page_section .about_detaile_text_content h1 {
  font-size: 20px;
  color: #c11f3b;
  margin-bottom: 4px;
}

.about_page_section .about_detaile_text_content h1 {
  font-size: 30px;
}

.about_page_section .about_detaile_text_content p {
  font-size: 14px;
  color: #000000;
  margin-bottom: 0;
}

.about_page_section .about_detaile_text_content a {
  color: #c11f3b;
}

.follow_me-link {
  text-decoration: none;
  border-bottom: 1px solid #c11f3b;
  color: #c11f3b;
  font-size: 13px;
}

.about_detaile_row {
  gap: 15px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}
.spinner {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company_video_preview {
  width: 100%;
  height: 100%;
}

.submit-company-btn {
  margin-top: 1.5rem;
} */

/* about page css end */

/* .featured_project_heading {
  color: #c11f3b;
  margin: 1rem 0;
}

.mobile_configuration_heading {
  color: #c11f3b; */
/* display: inline-block;
  padding: 0.5rem;
  margin-top: 1rem;
  background: #e7c3c985;
  border-radius: 0.2rem; */
/* } */

/* data table css start */

/* .custom_data_table_content .custom_data_table {
  width: 100%;
  border-spacing: 0;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: #00000000 0px 0px 0px 0px, #00000000 0px 0px 0px 0px,
    #0000001a 0px 1px 3px 0px, #0000000f 0px 1px 2px 0px;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_head
  .custom_data_table_heading {
  background: #c11f3b;
  font-weight: 700;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  text-transform: uppercase;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item {
  border-bottom: 1px solid #c1203b1f;
  padding: 10px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
}
.table_item {
  text-align: center;
}
.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row:last-child
  .custom_data_table_item {
  border-bottom: 0;
}

.custom_data_table_view_edit_btn_item_row {
  display: flex;
  align-items: center; */
/* justify-content: center; */
/* max-width: 70px;
  width: 100%;
  gap: 20px;
  margin: 0 auto;
} */

/* .custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border: none;
  height: 30px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 7px;
  transition: all 0.5s ease-in-out;
}

.custom_data_table_view_edit_btn_item_row
  .custom_data_table_view_edit_item_btn:hover {
  background: #c11f3b;
  color: #ffffff;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button,
.custom_data_table_button {
  padding: 6px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #c11f3b;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
  background: #c11f3b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  border: 0;
  margin: 0 auto;
  cursor: pointer;
}

.sold_button {
  margin-left: 0.5rem;
}

.custom_data_table_content
  .custom_data_table
  .custom_data_table_body
  .custom_data_table_row
  .custom_data_table_item
  .custom_data_table_button:hover,
.custom_data_table_button:hover {
  color: #ffffff;
  background: #c11f3b;
} */

/* data table css end */

/* plan page css start */

/* .plan_list_column {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px 18px 24px;
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;
}

.plan_list_column .plan_list_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 240px;
  max-height: 240px;
}

.plan_list_column .plan_list_img {
  position: relative;
  border-radius: 5px;
  width: 100%;
  min-height: 240px;
  max-height: 240px;
  overflow: hidden;
}

.plan_list_column .plan_list_img .plan_list_add_update_time {
  background: #0000006e;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px;
  box-shadow: 0px -2px 10px 1px #00000035;
  width: 100%;
  display: flex;
  align-items: center;
}

.plan_list_column .plan_list_img .plan_list_add_update_time p {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.plan_list_column .plan_list_img .plan_list_add_update_time p span {
  font-size: 12px;
  color: #fff;
  display: block;
  margin-top: 3px;
}

.plan_list_column
  .plan_list_img
  .plan_list_add_update_time
  .plan_list_update_time {
  margin-left: auto;
  text-align: right;
}

.plan_list_column .plan_list_body {
  padding: 18px 0 0;
  display: flex;
  flex-direction: column;
  min-height: 140px;
  height: auto;
}
.plan_list_column .plan_list_body .plan_list_title {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 10px;
}

.plan_list_column .plan_list_body .plan_list_name {
  font-weight: 500;
  display: block;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #c11f3b;
  text-decoration: none;
  transition: 0.3s;
}

.plan_list_column .plan_list_body .plan_list_bottom_btn {
  border-top: 2px solid #fbf1f3; */
/* margin-top: 14px; */
/* padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: auto;
} */

/* .plan_list_column .plan_list_body .plan_list_bottom_btn .plan_list_bottom {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #c11f3b;
  text-decoration: none;
  max-width: 165px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.plan_list_column .plan_list_body .plan_list_bottom_btn .plan_list_bottom svg {
  width: 25px;
  height: 25px;
  color: #c11f3b;
  background: #c11f3b1a;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}

.plan_list_column
  .plan_list_body
  .plan_list_bottom_btn
  .plan_list_bottom:hover
  svg {
  background: #c11f3b;
  color: #ffffff;
}

.plan_slider_box {
  position: relative;
  max-height: 350px;
  min-height: 350px;
}

.plan_slider_box .plan_slider_box_text_content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #0000008f;
  padding: 15px;
  box-shadow: 0px -3px 11px 3px #00000059;
}

.plan_slider_box .plan_slider_box_text_content .plan_slider_heading {
  font-size: 24px;
  color: #fff;
}

.plan_slider_box
  .plan_slider_box_text_content
  .plan_slider_heading
  ~ .plan_slider_peragraph {
  margin-top: 4px;
}
.plan_slider_box .plan_slider_box_text_content .plan_slider_peragraph {
  font-size: 16px;
  color: #fff;
}

.plan_page_section .about_page_slider {
  padding-bottom: 30px;
}

.plan_slider_box .plan_slider_box_text_content .plan_slider_category {
  font-size: 13px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 4px;
}

.plan_photo_view {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #ffffff;
  font-size: 25px;
  border: 1px solid white;
  padding: 2px 10px;
  border-radius: 4px;
}

.plan_photo_view:hover {
  opacity: 0.7;
} */

/* plan page css end */

/* customer properties page css start */

.customer_properties_column {
  background: #ffffff;
  border-radius: 5px;
  padding: 18px 18px 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 7px 7px 13px 0px rgb(50 50 50 / 5%);
}

.licence_column {
  border-left: 5px solid #0c5a81;
  border-radius: 8px;
}

.coupon_column {
  border-left: 5px solid #008080;
  border-radius: 8px;
  padding: 9px 18px 6px;
}

.message_column {
  border-left: 5px solid #5f0166;
  border-radius: 8px;
}

.customer_properties_column .customer_properties_user_type_name {
  border-radius: 5px;
  padding: 8px;
  border-left: 5px solid #c9a035;
}

.customer_properties_column .salon_page {
  border-left: 5px solid #830000;
  background: #ff939336;
}

.customer_properties_column .licence_page {
  padding: 0px;
  border-left: none;
  background: none;
  display: flex;
  justify-content: space-between;
}

.debited .customer_properties_user_type_name,
.customer_properties_user_type_name {
  background: #f4ecd9b5;
}

.credited .customer_properties_user_type_name {
  background: #039c291a;
}
.customer_properties_column
  .customer_properties_user_type_name
  .customer_properties_user_type {
  font-size: 11px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
}

.customer_properties_column
  .customer_properties_user_type_name
  .customer_properties_user_name {
  font-size: 16px;
  color: #2a2e30;
  font-weight: 600;
  display: block;
  margin-bottom: 0px;
}
.customer_properties_column
  .customer_properties_user_type_name
  .licence_user_name {
  font-size: 16px;
  color: #0c5a81;
  font-weight: 600;
  display: block;
  margin-bottom: 0px;
}

.customer_properties_column
  .customer_properties_user_type_name
  .message_user_name {
  font-size: 16px;
  color: #5f0166;
  font-weight: 600;
  display: block;
  margin-bottom: 0px;
}

.user_name_input {
  color: #000000;
}

.credited .customer_properties_user_type_name {
  border-left: 5px solid green;
}

.debited .customer_properties_user_name,
.customer_properties_user_name {
  color: #c11f3b;
}

.credited .customer_properties_user_name {
  color: green;
}

.customer_properties_column .customer_properties_other_details {
  margin-top: 10px;
  border-bottom: 1px solid #eed38e;
  padding-bottom: 10px;
}

.customer_properties_column .licence_inside_div {
  border-bottom: none;
}

.customer_properties_column .salon_page_line {
  margin-top: 10px;
  border-bottom: 1px solid #921616;
  padding-bottom: 10px;
}

.customer_properties_column
  .customer_properties_other_details
  .customer_properties_other_details_label {
  height: 20px;
  width: 20px;
  font-size: 13px;
  color: #7e5200;
  font-weight: 400;
  display: block;
  margin-bottom: 1px;
  font-weight: 500;
}

.customer_properties_column
  .customer_properties_other_details
  .customer_properties_other_details_licence_label {
  height: 20px;
  width: 80px;
  font-size: 13px;
  color: #263184;
  font-weight: 400;
  display: block;
  margin-bottom: 1px;
  font-weight: 500;
}
.customer_properties_column .customer_properties_other_details .msg_label {
  width: 40px;
  color: #410046;
}
.customer_properties_column .customer_properties_other_details .coupon_label {
  width: 50px;
  color: #004b4b;
}

.customer_properties_column
  .customer_properties_other_details
  .msg_label_payment {
  width: 60px;
  margin-top: -2px;
  color: #410046;
}

.customer_properties_column .customer_properties_other_details .salon_label {
  color: #860000;
}

.customer_properties_column
  .customer_properties_other_details
  .customer_properties_other_details_text {
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
  margin-left: 10px;
  color: #000000b5;
  display: block;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  margin-bottom: 8px;
}

.customer_properties_column
  .customer_properties_other_details
  .customer_properties_other_details_text_licence {
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  color: #000000b5;
  display: block;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 1.2;
  margin-bottom: 8px;
}

.customer_properties_column
  .customer_properties_discription_details
  .customer_properties_other_details_text {
  height: 85px;
}

.customer_properties_column.CUSTOMER .customer_properties_user_type_name {
  background: #ffb8001a;
  border-color: #ffb800;
}

.customer_properties_column.CUSTOMER
  .customer_properties_user_type_name
  .customer_properties_user_type,
.customer_properties_column.CUSTOMER
  .customer_properties_user_type_name
  .customer_properties_user_name,
.customer_properties_column.CUSTOMER
  .customer_properties_other_details
  .customer_properties_other_details_label {
  color: #ffb800;
}

.customer_properties_column.CUSTOMER .customer_properties_other_details {
  border-color: #ffb8001f;
}

.customer_properties_column .project_list_card_bottom_btn_row {
  margin-top: auto;
}

.customer_properties_column .customer_properties_other_details:nth-child(4) {
  margin-bottom: 5px;
}

.customer_properties_other_details
  .customer_properties_other_details_container {
  display: flex;
}

.property-next-btn {
  margin-top: 1rem;
}

input[type="checkbox" i] {
  accent-color: #c11f3b;
}

.notice_column {
  display: flex;
  flex-direction: column;
}

.notice_create_edit_date_row {
  display: flex;
  align-items: center;
}

.notice_create_edit_date_row .notice_edit_date_text {
  margin-left: auto;
  text-align: right;
}

.notice_create_edit_date_row .notice_date_heading {
  font-size: 16px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
  margin-bottom: 3px;
}

.notice_create_edit_date_row .notice_date_time_text {
  font-size: 11px;
  color: #c11f3b;
  font-weight: 600;
  display: block;
}

.notice_subject_discription_text {
  margin-top: 20px;
  margin-bottom: 10px;
}

.notice_subject_discription_text .notice_subject_text {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #00000035;
}

.notice_subject_discription_text .notice_discription_text {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: #000000;
}

.notice_column .project_list_card_bottom_btn_row {
  margin-top: auto;
}
.lead_btn_container {
  display: flex;
  justify-content: center;
}
.notice_column
  .project_list_card_bottom_btn_row
  .project_list_card_view_edit_btn {
  justify-content: flex-start;
}

.verify_btn {
  margin-top: 1rem;
}

.project_edit_feature_input ~ .project_edit_media_input_file {
  margin-top: 16px;
}

.project_edit_detail_divider {
  border-bottom: 1px solid #000000;
  padding-bottom: 20px;
  margin-bottom: 16px;
}

.project_edit_detail_divider .project_edit_detail_divider {
  border-bottom: 1px solid #00000038;
}

.project_edit_detail_divider:last-child,
.project_edit_detail_divider .project_edit_detail_divider:last-child {
  border-bottom: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.create_from_row .project_edit_detail_column_content {
  box-shadow: none;
  border-bottom: 2px dashed #00000047;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.create_from_row .project_edit_detail_column_content:last-child {
  border-bottom: 0;
}

.single_column_body .create_from_row,
.amenities_body .create_from_row {
  grid-gap: 0;
  gap: 0;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.create_from_row_no_data_msg {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}

.document_body .project_edit_feature_media_row {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.center_table_body {
  max-width: 1140px;
  margin: 0 auto;
}
.csr_data_component {
  margin-top: 1.5rem;
}

.lead_page_body .user_data_search_select_box_row .user_data_search {
  margin-right: 0;
}

.inquiry_complaint_detail_section {
  padding: 20px;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
}

.project_common_form_check_box label {
  position: relative;
  color: #4b4279;
  font-size: 20px;
  padding-left: 32px;
  cursor: pointer;
  line-height: 30px !important;
}

.project_common_form_check_box label::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 7px;
  width: 16px;
  height: 16px;
  border-radius: 20px;
  outline: 2px solid #4b4279;
  background: #fff;
  outline-offset: 0;
}
.project_common_form_check_box input:checked ~ label::before {
  left: 5px;
  top: 10px;
  width: 10px;
  height: 10px;
  outline: 5px solid #4b4279;
}
.check_green input:checked ~ label::before {
  outline: 5px solid #1d8d00 !important;
}
.check_red input:checked ~ label::before {
  outline: 5px solid #dd1111 !important;
}

.project_edit_feature_input .project_common_form_input_check_box_row {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  margin-top: 13px;
}
.project_edit_feature_input
  .project_common_form_input_check_box_row
  .project_common_form_check_box {
  margin: 0 14px;
}

.v-counter {
  border-radius: 6px;
  min-width: 110px;
  overflow: auto;
  padding: 0px 10px;
  border: 1px solid #c9a03540;
  margin: 38px 16px -1px 19px;
}

.v-counter_container {
  margin-top: 5px;
}

.counter_value {
  width: auto !important;
  max-width: 140px;
  margin: 0 5px;
  font-weight: 500;
  text-align: center;
}
.counter_plusbtn {
  font-size: 17px !important;
  font-weight: 700;
  margin-left: 3px;
}
.counter_minusbtn {
  font-size: 17px !important;
  font-weight: 700;
  margin-right: 3px;
}
.counter_plusbtn svg,
.counter_minusbtn svg {
  width: 20px;
  height: 20px;
}

.counter_value,
.counter_minusbtn,
.counter_plusbtn {
  display: inline-block;
  width: 20px;
  background-color: transparent;
  outline: none;
  border: none;
  text-align: center;
  cursor: pointer;
  padding: 0px;
  color: black;
  height: 33px;
  font-size: 14px;
}

.counter_plusbtn:disabled,
.counter_minusbtn:disabled {
  color: rgb(121, 121, 121);
  cursor: default;
}

/* customer properties page css end */

/* dashboard page css start */

.dashboard_body {
  padding: 25px;
}

.dashboard_top_column_row {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 30px;
  grid-gap: 30px;
  gap: 30px;
  margin-bottom: 1.5rem;
}

.dashboard_top_column_row .dashboard_top_column {
  box-shadow: 0 5px 20px rgb(146 153 184 / 3%);
  border-radius: 10px;
  background: #fff;
  padding: 25px;
  display: flex;
  align-items: flex-start;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_count_text {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  color: #272b41;
  margin-bottom: 0px;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_peragraph_text {
  margin-top: 2px;
  color: #868eae;
  font-size: 16px;
  /* line-height: 1.7857142857; */
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text {
  color: #868eae;
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text
  span {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: #20c997;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text
  span
  svg {
  margin-right: 2px;
}

.dashboard_top_column_row
  .dashboard_top_column.down_grad
  .dashboard_top_column_left_content
  .dashboard_top_column_left_bottom_text
  span {
  color: #ff4d4f;
}

.dashboard_top_column_row
  .dashboard_top_column
  .dashboard_top_column_main_icon {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c9a03566;
}

.project_licence_main_div {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  max-width: 1100px;
  margin: 0 auto;
  grid-gap: 20px;
}

@media (max-width: 1400px) {
  .project_licence_main_div {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 1150px) {
  .project_licence_main_div {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 700px) {
  .project_licence_main_div {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .project_common_form_input_check_box_row {
    display: block !important;
  }
}

@media (max-width: 1400px) {
  .dashboard_top_column_row {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 1100px) {
  .dashboard_top_column_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 1300px) {
  .project_edit_feature_input_dual_cointainer {
    display: block;
  }
  .v-counter {
    margin: 6px 10px 0px 0px;
    width: 200px;
  }
  .project_edit_feature_input_inside_div {
    width: 200px;
  }
}

@media (max-width: 650px) {
  .dashboard_top_column_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.dashboard_project-link {
  text-decoration: none;
}

.user_profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user_property {
  display: flex;
  justify-content: space-between;
}

.user_referred_btn {
  padding: 2px 13px;
}

.create_notice_heading {
  text-align: center;
  margin-bottom: 1rem;
}

.create_notice_buttons {
  display: flex;
  justify-content: center;
}
.create_notice_buttons > * {
  margin: 0 1rem;
}

.create_project_review {
  font-size: 12px;
  line-height: 15px;
  margin: 10px 0 8px 0;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #59667a;
  display: inline-block;
}

.rera_number {
  width: 100%;
}

.user_property {
  display: flex;
  justify-content: space-between;
}

.user_referred_btn {
  padding: 2px 13px;
}

.create_notice_heading {
  text-align: center;
  margin-bottom: 1rem;
}

.create_notice_buttons {
  display: flex;
  justify-content: center;
}
.create_notice_buttons > * {
  margin: 0 1rem;
}

.create_project_review {
  font-size: 12px;
  line-height: 15px;
  margin: 10px 0 8px 0;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #59667a;
  display: inline-block;
}

.rera_number {
  width: 100%;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin: 32px -5px 0;
}

.pagination .pagination__link--disabled {
  pointer-events: none;
}

.pagination li {
  padding: 0 5px;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #3f4358;
  color: #3f4358;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pagination__link {
  font-weight: bold;
}
.pagination__link--active a {
  color: #ffffff !important;
  background: #2a2e30;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.user_lead_heading {
  font-size: 25px;
  color: #c11f3b;
  margin-bottom: 6px;
  text-decoration: underline;
}

.user_detail_lead {
  margin-bottom: 1.5rem;
}

.user_lead-project {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.user_lead-status {
  font-size: 13px;
}

.user_detail-property {
  display: flex;
  justify-content: space-between;
}

.user_detail-nodata {
  text-align: center;
  color: #c11f3b;
}

.user_reward_header {
  display: flex;
  justify-content: space-between;
}

.user-detail_navbar {
  position: relative;
}

.user_wallet_status {
  position: absolute;
  right: 25px;
  display: flex;
  align-items: center;
}

.user_wallet_heading {
  margin-right: 1rem;
}

.user_detail-walletbalance {
  font-size: 15px;
  padding: 3px 13px;
  font-weight: 600;
  background: #c11f3b;
  border: 1px solid #c11f3b;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border-radius: 4px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
}

.user_detail-balance {
  margin-left: 10px;
  background: white;
  color: #c11f3b;
  padding: 3px 5px;
  border-radius: 5px;
}

.tower_dropdown {
  z-index: 99999;
}

.create_lead_referred {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.complaint_view_modalbtn {
  margin-top: 2rem;
}

.lead_status_rejected_confirmed {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.notice_header {
  justify-content: space-between;
}

.notice_user-type {
  font-size: 13px;
  font-weight: bold;
  color: #c11f3b;
}

.comman_center_btn_container {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 10px auto;
  width: 100%;
}

/* #chart{
  width: 130px !important;
  height: 93px !important;
} */

/* dashboard page css end */

/* media query css start */

@media (max-width: 1700px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column {
    max-height: 130px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    min-height: 162px;
  }
}

@media (max-width: 1600px) {
  .project_edit_main_content .col_lg_4 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .project_edit_main_content .col_lg_3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 1500px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 95px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column {
    max-height: 100px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    min-height: 132px;
  }
}

@media (max-width: 1400px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    min-height: 132px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 1300px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .project_edit_main_content .col_lg_4,
  .lead_list_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .project_edit_main_content .col_lg_3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .first_filter_container {
    margin-bottom: 5px;
  }
}

@media (max-width: 1200px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 95px;
  }

  .document_body .project_edit_feature_media_row {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 1100px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 991px) {
  .sidebar_container {
    max-width: 90px;
  }
  #sidebar_toggel:checked ~ .sidebar_container {
    max-width: 280px;
  }
  .sidebar_container .sidebar_nav {
    padding-left: 0;
  }
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link span,
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link::before {
    display: none;
  }
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_nav
    .sidebar_item
    .sidebar_link
    span,
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_nav
    .sidebar_item
    .sidebar_link::before {
    display: block;
    padding-left: 0;
  }
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link svg {
    margin: 0 auto;
  }
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_nav
    .sidebar_item
    .sidebar_link
    svg {
    margin-right: 16px;
    margin-left: 0;
  }
  .sidebar_container .sidebar_logo .small_sidebar_logo,
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_logo
    .full_sidebar_logo {
    display: block;
  }
  .sidebar_container .sidebar_logo .full_sidebar_logo,
  #sidebar_toggel:checked
    ~ .sidebar_container
    .sidebar_logo
    .small_sidebar_logo {
    display: none;
  }
  .main_content {
    max-width: calc(100% - 90px);
  }
  #sidebar_toggel:checked ~ .main_content {
    max-width: calc(100% - 280px);
  }
  .top_header_section {
    width: calc(100% - 89px);
  }
  .project_edit_content .project_edit_navbar {
    max-width: calc(100% - 89px);
  }
  #sidebar_toggel:checked ~ .main_content .top_header_section {
    width: calc(100% - 280px);
  }
  #sidebar_toggel:checked
    ~ .main_content
    .project_edit_content
    .project_edit_navbar {
    max-width: calc(100% - 280px);
  }
  .project_edit_main_content .project_edit_feature_media_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 175px;
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column {
    max-height: 175px;
  }

  .project_edit_main_link .comman_center_btn_container {
    display: block;
  }
}

@media (max-width: 767px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section
    .project_edit_media_image_column
    img {
    height: 125px;
  }
  .project_edit_main_content .col_lg_4,
  .lead_list_row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .top_header_section
    .top_navbar_nav
    .top_navbar_item
    .top_navbar_logout_btn
    span {
    display: none;
  }
  .top_header_section
    .top_navbar_nav
    .top_navbar_item
    .top_navbar_logout_btn
    svg {
    margin-right: 0;
  }
  .top_header_section .top_navbar_nav .top_navbar_item .top_navbar_logout_btn {
    padding: 6px 8px;
  }
  .footer_section {
    flex-direction: column;
    text-align: center;
    padding: 15px 10px;
  }
  .footer_section .footer_version_text {
    margin: 15px auto 0;
  }

  .creat_edit_project_btn_row_flex {
    display: inline-block;
  }

  /* .common_filter_input_box {
    position: relative;
    width: 300px;
  } */
}

@media (max-width: 600px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .customer_properties_column
    .customer_properties_user_type_name
    .customer_properties_user_type {
    font-size: 10px;
  }
  .customer_properties_column
    .customer_properties_user_type_name
    .customer_properties_user_name {
    font-size: 13px;
  }
  .customer_properties_column {
    padding: 15px 15px 10px;
  }

  .setting_form_res {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (max-width: 500px) {
  .project_edit_main_content
    .project_edit_detail_column_content
    .project_edit_media_image_section {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .top_header_section {
    padding: 15px 10px;
    width: calc(100% - 70px);
  }
  .sidebar_container {
    max-width: 70px;
    padding: 15px 10px;
  }
  .main_content {
    max-width: calc(100% - 70px);
  }
  .project_list_card .project_list_card_img {
    min-height: 160px;
    max-height: 160px;
  }
  .sidebar_container .sidebar_nav {
    margin: 30px -10.5px -15px;
  }
  .sidebar_container .sidebar_nav .sidebar_item .sidebar_link {
    padding: 12px;
  }
  .about_page_section .about_page_slider .slick-slider,
  .about_page_section .about_page_slider .slick-slider .slick-slide,
  .about_page_section .about_page_slider .slick-slider .slick-slide img {
    max-height: 320px;
    min-height: 320px;
    max-width: 320px;
  }
  .plan_slider_box {
    max-height: 320px;
    min-height: 320px;
  }

  .common_all_filter_row {
    display: inline-block;
  }
  .first_filter_container {
    margin-bottom: 5px;
  }

  .common_filter_input_box {
    position: relative;
    width: auto;
  }

  .project_date_range_input_container {
    margin-right: 10px;
  }
}

.go_back_btn {
  font-size: 18px;
  color: #2a2e30;
  background: transparent;
  text-decoration: underline;
  border: 0;
  padding: 0;
  display: flex;
  cursor: pointer;
  margin-top: 3px;
}

.reset_password_btn {
  padding: 4px 15px;
  font-weight: 600;
  font-size: 14px;
  background: #2a2e3033;
  color: #2a2e30;
  border: 0;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-width: 150px;
}
.reset_password_btn:hover {
  background: #2a2e30;
  color: #ffffff;
}

.go_back_btn svg {
  width: 20px;
  margin-top: 3px;
}

.no_data_found {
  justify-content: center;
  display: flex;
  font-size: 20px;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.no_data_found_without_table {
  display: flex;
  justify-content: center !important;
  max-width: 1100px !important;
  margin: auto;
}

.no_image_msg {
  display: flex;
  width: 100% !important;
}

.pagination_table {
  display: flex;
  justify-content: flex-end;
}

/* /////////////// modal Css start //////////////// */

.modal_main_component {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.modal_background_content {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.8);
}

.modal_container {
  width: 100%;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modal_container .modal_header_container {
  display: flex;
  justify-content: space-between;
}

.modal_container .modal_heading {
  display: inline-block;
  text-align: center;
  margin-top: 5px;
  width: 100%;
}

.modal_corner_close_btn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.booking_detail_modal {
  background-color: #f4f5f7 !important;
}

.modal_detail_container {
  padding: 18px;
  box-shadow: -3px 6px 13px 0.5px #0000000d;
  border-radius: 8px;
  background: white;
}

.modal_container .modal_paragraph {
  /* padding: 8px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
}
.modal_container .modal_paragraph p {
  margin-bottom: 0;
}

.delete_modal_p {
  justify-content: center;
}

.delete_modal_p p {
  font-size: 20px !important;
}

.modal_detail_div {
  display: flex;
}

.modal_head_title {
  font-size: 15px;
  color: #c9a035;
  margin-bottom: 0px;
  margin-right: 10px;
  display: flex;
  margin-top: 2px;
  min-width: 120px;
}

.modal_gender_img {
  height: 40px;
  width: 40px;
  margin-left: 10px;
  margin-top: -8px;
}

.modal_head_desc {
  font-size: 18px;
}

.modal_detail_span {
  color: rgb(44, 179, 10);
  margin-left: 8px;
}

.modal_input_field {
  display: block !important;
  font-size: 20px;
  font-size: 20px !important;
  color: #c9a035;
  /* padding: 8px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  /* text-align: center; */
}

.modal_input_field input {
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border-color: #c9a035;
  color: #2a2e30;
  width: 100% !important;
  padding: 12px 20px;
  background-clip: padding-box;
  border: 1px solid #c9a03540;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 6px;
  outline: 0;
  margin-bottom: 6px;
}

.modal_input_label_input_text input {
  padding-left: 75px !important;
}

.modal_input_field label {
  font-weight: 500 !important;
  font-size: 16px;
}

.modal_p_for_licence {
  font-size: 16px !important;
  color: #c9a035;
}

.modal_container .modal_btn_row {
  flex: 20% 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px;
}

.modal_btn_row_top_margin {
  margin-top: 30px !important;
}

.modal_container .modal_btn_row .modal_btn {
  width: 100%;
  height: 45px;
  margin: 10px;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

.msg_btn {
  width: 100% !important;
}

.modal_delete_btn {
  background-color: #c52f2f !important;
  color: #ffffff;
}
.modal_delete_btn:hover {
  background-color: #a82222 !important;
}

.modal_create_btn {
  background-color: #828de1 !important;
  color: #ffffff;
}
.modal_create_btn:hover {
  background-color: #6473df !important;
}

.modal_add_img_btn {
  width: 100% !important;
}
.modal_add_img_btn:disabled {
  background-color: #828de1 !important;
  color: #ffffff;
}

.modal_cancel_btn {
  background: #2a2e3033 !important;
  color: #2a2e30;
}

.modal_cancel_btn:hover {
  background: #2a2e304f !important;
}

.modal_update_btn {
  background: cornflowerblue !important;
  color: #2a2e30;
}

.modal_update_btn:hover {
  background: #719eed !important;
}

.zu_common_text_modal .modal-dialog {
  max-width: 500px;
}
.modal_add_img_content .modal-dialog {
  max-width: 700px !important;
}

.reset_password_modal .modal-dialog {
  max-width: 400px !important;
}

.modal-dialog {
  max-width: 500px;
}

.delete_container {
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}
.delete_container svg {
  width: 80px;
  color: #d64657;
  border: 3px solid #d64657;
  border-radius: 50%;
  padding: 15px;
}

/* ////////////modal Css end///////////// */

.spinner {
  position: relative;
  min-height: 75vh;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ////////////////////////// */

/* detail page Css start */

.project_view_list_item {
  font-size: 20px;
  color: #b38100;
  font-weight: 600;
}

.full_detail_container p,
.create_from_row p {
  font-weight: 600;
  margin-bottom: 0px;
}

.list_item_email {
  word-break: break-word;
}

.project_view_list_span {
  font-size: 16px;
  margin-left: 14px;
  color: red;
}

.msg_main_div {
  display: flex;
  justify-content: space-between;
}

.project_item_svg_row {
  display: flex;
}

/* /////// counter ////////// */
.quantity {
  display: flex;
  align-items: center;
  padding: 0;
}
.quantity .quantity_row {
  border: 1px solid #c9a035;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.quantity__minus,
.quantity__plus {
  display: block;
  width: 30px;
  height: 30px;
  margin: 0;
  background: #c9a035;
  border: 0;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  line-height: 23px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quantity__minus:hover,
.quantity__plus:hover {
  background: #c9a035;
  color: #ffffff;
}

.quantity__minus:disabled,
.quantity__plus:disabled,
button[disabled] {
  opacity: 0.5;
}

.quantity__input {
  width: 60px;
  height: 30px;
  margin: 0;
  padding: 0;
  text-align: center;
  background: #ffffff;
  color: #000000;
  border: 0;
}
.quantity__minus:link,
.quantity__plus:link {
  color: #ffffff;
}
.quantity__minus:visited,
.quantity__plus:visited {
  color: #ffffff;
}

.quantity_desc {
  font-size: 20px;
  margin-bottom: 0;
  margin-left: 10px;
}

.project_input_label {
  margin-top: 16px;
  color: #c9a035;
  font-size: 16px;
}

/* /////////////////////// */
.active-on {
  color: #3bb54a !important;
  padding: 0 !important;
  font-size: 25px !important;
}

.active-off {
  color: #ff0000 !important;
  padding: 0 !important;
  font-size: 25px !important;
}

.active_status-on {
  color: #3bb54a !important;
  padding: 0 !important;
  font-size: 18px !important;
}

.active_status-off {
  color: #ff0000 !important;
  padding: 0 !important;
  font-size: 18px !important;
}

.green {
  border-left: 4px solid #3bb54a !important;
  background: #ffffff;
}

/* /////////////////////// */

.project_service_main_div {
  display: flex;
  max-width: 1600px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.project_service_main_div_row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project_service_main_div_column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.service_icon_card {
  height: 200px;
  width: 200px;
  border-radius: 5px;
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
  padding: 20px;
  margin: 10px;
  /* width: auto; */
  transition: all 0.3s ease-out;
  background-color: #ffffff;
}

.service_icon_card img {
  position: relative;
  width: 155px;
  max-height: 155px;
}

.img_delete_btn {
  /*display: flex;*/
  /*justify-content: flex-end;*/
  /*margin-top: -14px;*/
  /*margin-right: -15px;*/
}

.icon_button_container {
  margin-right: -15px;
  margin-top: -14px;
  margin-bottom: 5px;
  display: flex;
  justify-content: end;
  grid-gap: 5px;
  gap: 5px;
}

.img_delete_btn svg,
.img_update_btn svg {
  color: #ffffff;
  width: 20px;
  height: 20px;
  background-color: #2a2e3059;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  padding: 2px;
}

.img_delete_btn svg:hover {
  color: #ffffff;
  background-color: #c52f2f;
}

.img_update_btn svg:hover {
  color: #ffffff;
  background-color: cornflowerblue;
}

.msg_edit_btn svg {
  color: #ffffff;
  display: flex;
  width: 22px;
  height: 22px;
  background-color: #2a2e3059;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  padding: 2px;
}

.msg_edit_btn svg:hover {
  color: #ffffff;
  background-color: #0a0a0a;
}
/* ////////////////////// */

.img_prev_container {
  overflow-y: auto !important;

  border: 1px dashed #000;
  padding: 15px;
  border-radius: 4px;
  max-height: 207px;
  min-height: 300px;
  height: auto;
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 15px;
  grid-gap: 15px;
  gap: 15px;
}

.img_prev_inside_container {
  max-height: 175px;
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.img_prev_inside_container img {
  width: 100%;
  height: 175px;
  object-fit: contain;
}

.image_gallery_remove_btn {
  width: 18px;
  height: 18px;
  border: 1px solid #c11f3b;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  background: #ffffff;
  color: #c11f3b;
  cursor: pointer;
}

.image_gallery_remove_btn span {
  width: 10px;
  height: 2px;
  background: #c11f3b;
  border-radius: 50px;
  display: block;
}

.image_gallery_remove_btn:hover {
  background: #c11f3b;
}

.image_gallery_remove_btn:hover span {
  background: #fff;
}

/* ////// radio btn ///////// */

.radio {
  /* background: #454857;
  padding: 4px !important;
  border-radius: 3px !important;
  box-shadow: inset 0 0 0 3px rgba(35, 33, 45, 0.3),
    0 0 0 3px rgba(185, 185, 185, 0.3); */
  position: relative;
  width: 58px !important;
}

.radio input {
  height: 100% !important;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  padding: 4px 4px !important;
  background: #d4d5d6;
  color: black;
  font-size: 14px;
  transition: all 100ms linear;
  width: 25px !important;
}

.radio input:checked {
  background-color: #c9a035;
  color: #fff;
  box-shadow: 0 1px 1px #0000002e;
  text-shadow: 0 1px 0px #79485f7a;
}

.radio input:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}

/* ////////////////Tabbbsss///////////////////////// */

.tab {
  /* background: #454857;
  padding: 4px !important;
  border-radius: 3px !important;
  box-shadow: inset 0 0 0 3px rgba(35, 33, 45, 0.3),
    0 0 0 3px rgba(185, 185, 185, 0.3); */
  position: relative;
  /* width: 200px !important; */
}

.tab input {
  height: 100% !important;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  padding: 4px 4px !important;
  background: #d4d5d6;
  color: black;
  font-size: 14px;
  transition: all 100ms linear;
  width: 135px !important;
  border: 1px solid #ffffff;
  border-radius: 4px;
}

.tab input:checked {
  background-color: #c9a035;
  color: #fff;
  text-shadow: 0 1px 0px #79485f7a;
}

.tab input:before {
  content: attr(label);
  display: inline-block;
  text-align: center;
  width: 100%;
}

.tab input:disabled {
  display: none;
}

/* //////////////////////////////////// */

.credited_green svg {
  color: #189d16 !important;
}
.debited_red svg {
  color: red !important;
}

.form-control:focus {
  box-shadow: 0 0 0 .05rem rgba(13,110,253,.25);
}

